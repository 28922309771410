
import { mapActions } from 'vuex';

import { EXPERIMENT_NAMES, LANDING_PAGE_IDS, QUIZ_VARIANTS } from '@/services/constants.js';

export default {
  components: {
    LandingPagesLoseWeight: () => import('@/components/landing-pages/lose-weight/lose-weight'),
  },
  head() {
    return {
      title: this.$t('landingPage.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('landingPage.meta.description'),
        },
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },
  computed: {
    getActiveExperiment() {
      const activeExperiments = this.$exp?.activeExperiments || [];
      return activeExperiments.find((exp) =>
        [EXPERIMENT_NAMES.RH_NUTRITION_QUIZ_CHECKOUT_COPY_CHANGES_META_ISSUE].includes(exp.experiment.name),
      );
    },
  },
  created() {
    if (!this.getActiveExperiment) {
      this.setQuizVariantAction(QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY);
    }
    this.setLandingPageIdAction(LANDING_PAGE_IDS.BODY_NUTRITION);
  },
  mounted() {
    this.landingPageGtmHandler();
  },
  methods: {
    ...mapActions('quiz', ['setQuizVariantAction', 'setLandingPageIdAction']),
  },
};
